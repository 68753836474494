import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [];

export const dictionary = {
		"/": [~19],
		"/admin/user": [20,[2]],
		"/api": [21,[3],[4]],
		"/api/open": [22,[3],[4]],
		"/api/open/reference": [23,[3],[4]],
		"/api/open/try": [24,[3],[4]],
		"/api/postman": [25,[3],[4]],
		"/api/reference": [26,[3],[4]],
		"/api/status": [27,[3],[4]],
		"/api/try": [28,[3],[4]],
		"/company/[companyId]": [~29,[5],[6]],
		"/excel": [30,[7],[8]],
		"/excel/authentication": [31,[7],[8]],
		"/excel/installation": [32,[7],[8]],
		"/excel/queue": [33,[7],[8]],
		"/excel/search": [34,[7],[8]],
		"/login/callback": [35,[9]],
		"/me/api-key": [~36,[10]],
		"/me/billing": [~37,[10]],
		"/me/history": [~38,[10]],
		"/me/list": [~39,[10]],
		"/me/notification": [~40,[10]],
		"/me/profile": [~41,[10]],
		"/me/subscription": [~42,[10]],
		"/me/unsubscribe": [43,[10]],
		"/me/usage": [~44,[10]],
		"/office": [45,[11],[12]],
		"/office/bulk": [47,[11],[12]],
		"/office/preference": [48,[11],[12]],
		"/office/[taxId]": [~46,[11],[12]],
		"/person": [49,[13],[14]],
		"/person/[personId]": [~50,[13],[14]],
		"/subscription": [~51,[15],[16]],
		"/subscription/about": [~52,[15],[16]],
		"/subscription/checkout": [~53,[15],[16]],
		"/subscription/checkout/[subscriptionId]": [54,[15],[16]],
		"/subscription/credit": [55,[15],[16]],
		"/subscription/estimate": [~56,[15],[16]],
		"/subscription/faq": [57,[15],[16]],
		"/subscription/privacy": [58,[15],[16]],
		"/subscription/tos": [59,[15],[16]],
		"/ui/accordion": [60,[17],[18]],
		"/ui/anchor": [61,[17],[18]],
		"/ui/button": [62,[17],[18]],
		"/ui/cache": [63,[17],[18]],
		"/ui/card": [64,[17],[18]],
		"/ui/chart": [65,[17],[18]],
		"/ui/checkbox": [66,[17],[18]],
		"/ui/code": [67,[17],[18]],
		"/ui/copy": [68,[17],[18]],
		"/ui/credit": [69,[17],[18]],
		"/ui/divider": [70,[17],[18]],
		"/ui/form": [71,[17],[18]],
		"/ui/info": [72,[17],[18]],
		"/ui/input-chip": [74,[17],[18]],
		"/ui/input": [73,[17],[18]],
		"/ui/list": [75,[17],[18]],
		"/ui/notice": [76,[17],[18]],
		"/ui/paragraph": [77,[17],[18]],
		"/ui/radio": [78,[17],[18]],
		"/ui/select": [79,[17],[18]],
		"/ui/status": [80,[17],[18]],
		"/ui/stepper": [81,[17],[18]],
		"/ui/table": [82,[17],[18]],
		"/ui/text-area": [83,[17],[18]],
		"/ui/tooltip": [84,[17],[18]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';