import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';

Sentry.init({
  enabled: PUBLIC_ENV !== 'local',
  environment: PUBLIC_ENV,
  dsn: 'https://368c4939b51f7c3320d84a19eea86458@o391781.ingest.us.sentry.io/4507022852620288',
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  integrations: (i) => i.filter((i) => i.name !== 'GlobalHandlers'),
});
